.warning {
    background: #d7795a;
    border-radius: 4px;
    align-self: stretch;
    padding: 10px;
    text-align: center;
    font-family: Satoshi, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: 0.05em;
    color: #fff;
}

.stepper {
    width: 100%;
}

.title {
    font-family: Satoshi, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 131%;
    letter-spacing: 0.05em;
}

.label {
    font-family: Satoshi, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 131%;
    letter-spacing: 0.05em;
}

.error {
    font-family: Satoshi, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 131%;
    letter-spacing: 0.05em;
    color: #da2d6b;
}
